import { Controller } from 'react-hook-form';
import Select from 'react-select';

export const UbigeoQuestion = ({
  question,
  methods,
  departments,
  provinces,
  errorDepartment, 
  errorProvince, 
  districts,
  errorDistrict
}) => {
  if (question === 'departamento') {
    return (
      <>
        <label
          className="block text-gray-700 text-sm font-semibold mb-4 mt-4"
          htmlFor="question_departamento"
        >
          {question.order}. {question.description}
          {question.is_required === 1 && (
            <span className="text-red-500 ml-1">*</span>
          )}
        </label>
        <Controller
          name="question_departamento"
          control={methods.control}
          defaultValue=""
          render={({ field }) => {
            console.log('field', field);
            return (
              <Select
                {...field}
                value={departments.find(
                  (option) => option.code === field.value
                )}
                onChange={(option) => {
                  field.onChange(option ? option.code : '');
                }}
                options={departments}
                isClearable
                isSearchable
                getOptionLabel={(option) => option.department}
                getOptionValue={(option) => option.code}
              />
            );
          }}
        />
        {question.is_required === 1 &&
          errorDepartment &&
          errorDepartment.message && (
            <p className="text-red-500 text-xs italic">
              {errorDepartment.message.toString()}
            </p>
          )}
      </>
    );
  } else if (question.includes('provincia')) {
    return (
      <>
        <label
          className="block text-gray-700 text-sm font-semibold mb-4 mt-4"
          htmlFor="question_provincia"
        >
          {question.id}. {question.description}
          {question.is_required === 1 && (
            <span className="text-red-500 ml-1">*</span>
          )}
        </label>
        <Controller
          name="question_provincia"
          control={methods.control}
          defaultValue=""
          render={({ field }) => (
            <Select
              {...field}
              value={provinces.find((option) => option.id === field.value)}
              onChange={(option) => {
                console.log('New Provincia Selected:', option);
                field.onChange(option ? option.id : '');
              }}
              options={provinces}
              isClearable
              isSearchable
              getOptionLabel={(option) => option.province}
              getOptionValue={(option) => option.id}
            />
          )}
        />

        {question.is_required === 1 &&
          errorProvince &&
          errorProvince.message && (
            <p className="text-red-500 text-xs italic">
              {errorProvince.message.toString()}
            </p>
          )}
      </>
    );
  } else if (question.includes('distrito')) {
    return (
      <>
        <label
          className="block text-gray-700 text-sm font-semibold mb-4 mt-4"
          htmlFor="question_distrito"
        >
          {question.id}. {question.description}
          {question.is_required === 1 && (
            <span className="text-red-500 ml-1">*</span>
          )}
        </label>
        <Controller
          name="question_distrito"
          control={methods.control}
          defaultValue=""
          render={({ field }) => (
            <Select
              {...field}
              value={districts.find((option) => option.id === field.value)}
              onChange={(option) => {
                console.log('Selected District:', option);
                field.onChange(option ? option.id : '');
              }}
              options={districts}
              isClearable
              isSearchable
              getOptionLabel={(option) => option.description}
              getOptionValue={(option) => option.id}
            />
          )}
        />
        {question.is_required === 1 &&
          errorDistrict &&
          errorDistrict.message && (
            <p className="text-red-500 text-xs italic">
              {errorDistrict.message.toString()}
            </p>
          )}
      </>
    );
  }
};
