import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
import { formatQuestion } from '../utils/formatQuestion';
registerLocale('es', es);

interface Question {
  id: number;
  description: string;
  question_type: {
    description: string;
  };
  options?: any[];
  is_required: number;
}

interface QuestionProps {
  question: Question;
}

const Label = ({ htmlFor, children, tooltip, isRequired }) => (
  <label
    htmlFor={htmlFor}
    className="block text-gray-700 text-sm font-semibold mb-2"
  >
    {children}
    {isRequired && <span className="text-red-500 ml-1">*</span>}
    {tooltip && (
      <span className="text-xs text-gray-500 ml-1">
        - Verificar en{' '}
        <a
          href="https://servicios.sbs.gob.pe/ReporteSituacionPrevisional/Afil_Consulta.aspx"
          target="_blank"
          rel="noreferrer"
          className="text-blue-500 underline"
        >
          este link
        </a>
      </span>
    )}
  </label>
);

export const formatDateToString = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export const parseDateFromString = (value: string): Date | null => {
  if (!value) return null;
  if (value.includes('-')) {
    // yyyy-mm-dd format
    const [year, month, day] = value.split('-').map(Number);
    return new Date(year, month - 1, day);
  } else if (value.includes('/')) {
    // dd/mm/yyyy format
    const [day, month, year] = value.split('/').map(Number);
    return new Date(year, month - 1, day);
  }
  return null;
};

const QuestionComponent: React.FC<
  QuestionProps & {
    disabled?: boolean;
    onChange?: (value: any) => void;
    options?: any[];
  }
> = ({ question, disabled, onChange, options }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = errors[
    `question_${formatQuestion(question.description)}`
  ] as any;
  const isPensionSystem = question.description
    .toLowerCase()
    .includes('sistema de pensiones');
  const tooltipText = isPensionSystem
    ? `Verificar en <a href="https://servicios.sbs.gob.pe/ReporteSituacionPrevisional/Afil_Consulta.aspx" target="_blank" rel="noreferrer">este link</a>`
    : null;
  return (
    <div className="mb-6">
      <Label
        htmlFor={`question_${question.id}`}
        isRequired={question.is_required === 1}
        tooltip={
          question.description.toLocaleLowerCase().includes('sistema de pensiones')
            ? 'Verificar en este link'
            : undefined
        }
      >
        {question.id}. {question.description}
      </Label>
      <Controller
        name={`question_${formatQuestion(question.description)}`}
        control={control}
        defaultValue=""
        disabled={disabled}
        render={({ field }) => {
          switch (question.question_type.description.toLowerCase()) {
            case 'select':
              return (
                <>
                  <Select
                    {...field}
                    options={options || question.options}
                    value={question.options?.find(
                      (option) => option.id === field.value
                    )}
                    onChange={(option) => {
                      field.onChange(option.id);
                      if (onChange) onChange(option);
                    }}
                    className="text-base w-full mt-1 cursor-pointer"
                    isClearable
                    placeholder="Seleccione una opción"
                    noOptionsMessage={() => 'No hay opciones disponibles'}
                    getOptionLabel={(option) =>
                      option.description || option.department || option.province
                    }
                    getOptionValue={(option) => option.id}
                    aria-invalid={!!error}
                    aria-describedby={`error-${question.id}`}
                    isDisabled={disabled}
                    isMulti={question.description
                      .toLowerCase()
                      .includes('hobbies')}
                  />
                  {error && (
                    <p
                      className="text-red-500 text-xs italic"
                      id={`error-${question.id}`}
                    >
                      {error.message}
                    </p>
                  )}
                </>
              );
            case 'date':
              return (
                <>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={
                      field.value ? parseDateFromString(field.value) : null
                    }
                    onChange={(date) =>
                      field.onChange(date ? formatDateToString(date) : '')
                    }
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    locale="es"
                    placeholderText="dd/mm/aaaa"
                    aria-invalid={!!error}
                    aria-describedby={`error-${question.id}`}
                    disabled={disabled}
                  />
                  {error && (
                    <p
                      className="text-red-500 text-xs italic"
                      id={`error-${question.id}`}
                    >
                      {error.message}
                    </p>
                  )}
                </>
              );
            case 'number':
              return (
                <>
                  <input
                    {...field}
                    type="number"
                    disabled={disabled}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    aria-invalid={!!error}
                    aria-describedby={`error-${question.id}`}
                    min="0"
                    onKeyPress={(e) => {
                      if (e.key === '-' || e.key === '+') e.preventDefault();
                    }}
                  />
                  {error && (
                    <p
                      className="text-red-500 text-xs italic"
                      id={`error-${question.id}`}
                    >
                      {error.message}
                    </p>
                  )}
                </>
              );
            default:
              return (
                <>
                  <input
                    {...field}
                    type="text"
                    disabled={disabled}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    aria-invalid={!!error}
                    aria-describedby={`error-${question.id}`}
                  />
                  {error && (
                    <p
                      className="text-red-500 text-xs italic"
                      id={`error-${question.id}`}
                    >
                      {error.message}
                    </p>
                  )}
                </>
              );
          }
        }}
      />
    </div>
  );
};

export default React.memo(QuestionComponent);
