import { FormProvider } from 'react-hook-form';
import { CategorySelector } from './CategorySelector';
import QuestionComponent from './QuestionComponent';
import { Toaster } from 'react-hot-toast';
import { UbigeoQuestion } from './UbigeoQuestion';
import { HobbyQuestion } from './HobbyQuestion';
import { formatQuestion } from '../utils/formatQuestion';

export const FormContent = ({
  categories,
  nameForm,
  activeCategoryIndex,
  setActiveCategoryIndex,
  methods,
  onSubmit,
  questionsByCategory,
  activeCategory,
  goToPreviousCategory,
  disabledFields,
  departments,
  provinces,
  districts,
  errorDepartment,
  errorProvince,
  errorDistrict,
  disabledSearchFields
}) => {
  const currentCategoryQuestions = questionsByCategory[activeCategory] || [];

  const ubigeoItems = ['departamento', 'provincia', 'distrito'];

  const isUbigeoQuestion = (description) =>
    ubigeoItems.some((item) => description.includes(item));

  const isHobbyQuestion = (description) =>
    description.includes('hobbies');

  return (
    <>
      <div className="flex justify-between bg-white p-4 py-6 rounded-lg shadow-md mb-6">
        <h2 className="text-xl font-semibold">{nameForm}</h2>
      </div>

      <CategorySelector
        categories={categories}
        activeCategoryIndex={activeCategoryIndex}
        setActiveCategoryIndex={setActiveCategoryIndex}
      />

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="bg-white p-4 rounded-lg"
        >
          {currentCategoryQuestions.map((question) => {
            const { id, description, options } = question;
            const formattedDescription = description.toLowerCase();

            if (isUbigeoQuestion(formattedDescription)) {
              return (
                <UbigeoQuestion
                  key={id}
                  question={description}
                  methods={methods}
                  departments={departments}
                  provinces={provinces}
                  districts={districts}
                  errorDepartment={errorDepartment}
                  errorProvince={errorProvince}
                  errorDistrict={errorDistrict}
                />
              );
            } else if (isHobbyQuestion(formattedDescription)) {
              return <HobbyQuestion key={id} question={question} />;
            } else {
              return (
                <QuestionComponent
                  key={id}
                  question={question}
                  options={options}
                  disabled={
                    disabledFields[
                      `question_${formatQuestion(description)}` 
                    ]
                    || disabledSearchFields[
                      `question_${formatQuestion(description)}`
                    ]
                  }
                />
              );
            }
          })}
          {methods.errors && (
            <div className="text-red-500 mt-2">{methods.errors}</div>
          )}
          <div className="flex justify-between mt-4">
            <button
              type="button"
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
              onClick={goToPreviousCategory}
              disabled={activeCategoryIndex === 0}
            >
              Previo
            </button>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
            >
              {activeCategoryIndex === categories.length - 1
                ? 'Finalizar'
                : 'Siguiente'}
            </button>
          </div>
        </form>
      </FormProvider>
      <Toaster />
    </>
  );
};